// если вы хотите использовать sass-переменные - удалите root
// colors
:root {
  // base
  --font-family: "Roboto", sans-serif;
  --content-width: 1250px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // colors
  --light-color: #fff;
  --background-color: #041720;
  --background-block: #05202D;
  --main-color: #69910F;
  --text-color: #041720;
  --link-color: #B0CB1F;
}
