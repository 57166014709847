html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  color: var(--light-color);
  min-width: 320px;
  min-height: 100%;
  font-weight: 300;
  font-family: var(--font-family, sans-serif);
  font-size: 20px;
  line-height: 23px;
  background-color: var(--background-color);
  @include tablet {
    font-size: 16px;
    line-height: 19px;
  }
  @include mobile {
    font-weight: 300;
    font-size: 14px;
  }
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: hidden; // если используете на сайте position: sticky - уберите эту настройку
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.title {
  font-weight: 800;
  font-size: 48px;
  line-height: 56px;
  text-transform: uppercase;
  @include tablet {
    font-size: 32px;
    line-height: 40px;
  }
  @include mobile {
    font-size: 26px;
    line-height: 30px;
  }
}
.subtitle {
  font-weight: 800;
  font-size: 36px;
  line-height: 42px;
  text-transform: uppercase;
  @include tablet {
    font-size: 26px;
    line-height: 32px;
  }
  @include mobile {
    font-size: 20px;
    line-height: 24px;
  }
}

.list {
  li {
    position: relative;
    display: flex;
    &:before {
      content: '';
      width: 8px;
      flex: 0 0 8px;
      height: 11px;
      margin-right: 20px;
      margin-top: 5px;
      background-image: url('/wp-content/themes/blackriver/front/app/img/drop.svg');
      background-size: contain;
      background-repeat: no-repeat;
    }
    &:not(:last-child) {
      margin-bottom: 31px;
      @include small-tablet {
        margin-bottom: 25px;
      }
    }
  }
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.btn {
  border-radius: 4px;
  background-color: var(--main-color);
  border: none;
  color: var(--text-color);
  text-transform: uppercase;
  padding: 5px 17px 5px 14px;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    background-color: #D0A73E;
  }
  &:active {
    background-color: #E0BA5A;
  }

  @include tablet {
    padding: 3px 10px;
    font-size: 12px;
  }

}
.tel {
  color: var(--light-color);
  position: relative;
  text-decoration: underline;
  text-decoration-style: dotted;
  text-underline-position: under;
  display: flex;
  align-items: center;
  margin-right: 35px;
  @include tablet {
    margin-bottom: 10px;
    margin-right: 0;
    justify-content: flex-end;
  }
  &:before {
    content: '';
    background-image: url("/wp-content/themes/blackriver/front/app/img/tel.svg");
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
    display: block;
    margin-right: 7px;
    @include tablet {
      width: 16px;
      height: 16px;
      background-size: contain;
    }
  }
}

a {
  color: var(--main-color);
  text-decoration: underline;
}
.more {
  position: relative;
  display: flex;
  color: var(--main-color);
  text-decoration: underline;
  border: none;
  outline: none;
  background: transparent;
  &:before {
    content: '';
    flex: 0 0 28px;
    width: 28px;
    height: 28px;
    margin-right: 9px;
    background-image: url("/wp-content/themes/blackriver/front/app/img/more.svg");
    background-repeat: no-repeat;
    background-size: contain;
    @include small-tablet {
      flex: 0 0 18px;
      height: 18px;
      margin-right: 3px;
    }
  }
}


.list {

}
.btn {

}
.input {

}
.title {

}
.subtitle {

}
