.footer {
  background-color: var(--background-block);
  padding: 10px 0 41px 0;
  @include small-tablet {
    padding-top: 20px;
  }
  .container {
    display: flex;
    justify-content: space-between;
    @include small-tablet {
      flex-direction: column;
      align-items: center;
    }
  }
  &__wrapper.bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @include small-tablet {
      align-items: center;
    }
  }
  &__menu {
    margin-top: 40px;
    padding: 0;
    .menu__list {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0;
      width: 100%;
      margin: 0;
      @include small-tablet {
        flex-direction: column;
        align-items: center;
        margin-bottom: 0;
      }
      .list__item {
        font-weight: 400;
        font-size: 16px;
        text-transform: uppercase;
        &:not(:last-child) {
          margin-right: 32px;
          @include small-tablet {
            margin-right: 0;
            margin-bottom: 19px;
          }
        }
        .item__link {
          color: var(--light-color);
          text-decoration: none;
          &:hover {
            color: var(--main-color);
          }
        }
      }
    }
  }
  &__tel {
    margin-top: 27px;
    @include tablet {
      justify-content: flex-start;
    }
    @include small-tablet {
      justify-content: center;
      margin-top: 15px;
    }
  }
  &__social {
    margin: 42px 0 0 0;
    padding: 0;
    list-style: none;
    display: flex;
    .social__item {
      &:not(:last-child) {
        margin-right: 32px;
      }
    }
  }
}
