.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s all;
  z-index: 20;
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 0;
  @include desktop {
    width: 570px;
  }
  @include mobile {
    padding-left: 0;
    padding-right: 0;
    width: 90%;
  }
  &.active {
    transform: translate(-50%, -50%);
    transition: 0.5s all;
  }
  .container {
    border-radius: 6px;
    position: relative;
    .form__title {
      @include desktop {
        font-size: 27px;
      }
    }
    form label {
      @include desktop {
        margin-bottom: 13px;
      }
      .form__name, .form__tel {
        width: 425px;
        @include mobile {
          width: 100%;
        }
      }
    }
  }
  &__bg {
    z-index: 20;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,0.5);
    opacity: 0;
    pointer-events: none;
    transition: 0.5s all;
    &.active {
      opacity: 1;
      pointer-events: all;
      transition: 0.5s all;
    }

  }
  .close-popup {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    &:after {
      display: inline-block;
      content: "\00d7";
      font-size: 40px;
      color: #CDA130;
    }
    &:hover {
      &:before, &:after {
        background-color: var(--main-color);
      }
    }
  }
}
