.main {
  margin-top: 24px;
  @include mobile {
    margin-top: -40px;
  }
  .delivery {
    .container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @include tablet {
        justify-content: space-around;
      }
    }
    &__wrapper {
      display: flex;
      justify-content: space-between;
      flex: 0 0 100%;
      @include mobile {
        flex-direction: column-reverse;
      }

    }
    &__title {

    }
    &__btn {
      margin-top: 29px;
      @include mobile {
        margin-top: 20px;
      }
    }
    &__text {
      @include small-tablet {
        flex: 0 0 50%;
      }
      @include mobile {
        flex: 0 0 auto;
      }
    }
    .btn {
      font-size: 24px;
      padding: 11px 20px 9px 20px;
      @include small-tablet {
        font-size: 14px;
        padding: 7px 10px 7px 10px;
      }
    }
    &__img {
      @include small-tablet {
        width: 422px;
        object-fit: contain;
      }
      @include mobile {
        width: 100%;
      }
    }
    &__auto, &__train {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 30px;
      flex: 0 0 580px;
      border-radius: 6px;
      border: 1px solid #374D57;
      padding: 37px 149px 29px 32px;
      position: relative;
      @include tablet {
        flex: 0 0 45%;
      }
      @include small-tablet {
        flex: 0 0 100%;
        display: block;
        padding: 19px 132px 19px 19px ;
      }
      @include mobile {
        padding: 19px;
        margin-top: 40px;
      }
      &:before {
        content: '';
        width: 162px;
        height: 162px;
        position: absolute;
        right: -37px;
        top: -46px;
        background: url('/wp-content/themes/blackriver/front/app/img/hero-icon.svg');
        background-size: contain;
        @include small-tablet {
          right: -19px;
          top: -32px;
          width: 150px;
          height: 150px;
        }
      }
      .train, .auto {
        &__title {
          font-size: 20px;
          font-weight: 700;
          line-height: 110%;
          text-transform: uppercase;
          margin: 0 0 16px 0;
          @include tablet {
            font-size: 18px;
          }
          @include mobile {
            font-size: 14px;
            margin-bottom: 30px;
            max-width: 182px;
          }
        }
        p {
          font-size: 18px;
          line-height: 120%;
          @include tablet {
            font-size: 16px;
          }
          @include mobile {
            font-size: 14px;
          }
        }
        &__btn {
          margin-top: 35px;
          width: fit-content;
          @include small-tablet {
            margin-top: 19px;
          }
          @include mobile {
            margin: 0 auto;
            display: block;
          }
        }

      }
    }
    &__train {
      @include small-tablet {
        padding: 19px 19px 19px 149px;
      }
      @include mobile {
        padding: 19px;
      }
      &:before {
        background: url('/wp-content/themes/blackriver/front/app/img/hero-icon2.svg');
        background-size: contain;
        @include small-tablet {
          right: inherit;
          left: -14px;
        }
        @include mobile {
          top: -20px;
        }
      }
      .train__title {
        @include mobile {
          margin-left: 132px;
        }
      }
    }
  }
  .advantages {
    margin-top: 56px;
    padding: 54px 0 73px;
    background: url('/wp-content/themes/blackriver/front/app/img/advantages-bg.webp') no-repeat;
    background-position: center;
  ;
    background-size: cover;
    @include small-tablet {
      margin-top: 34px;
      padding: 39px 0 41px;
    }
    &__title {
      margin: 0 0 41px 0;
      @include small-tablet {
        margin-bottom: 31px;
      }
    }
  }
  .cert {
    margin-top: 75px;
    @include tablet {
      margin-top: 50px;
    }
    @include small-tablet {
      margin-top: 40px;
    }
    @include mobile {
      margin-top: 25px;
    }
    &__title {
      margin: 0 0 50px 0;
      @include small-tablet {
        margin-top: 35px;
      }
      @include mobile {
        margin: 25px;
      }
    }
    &__list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 40px 100px;
      @include tablet {
        column-gap: 70px;
      }
      @include small-tablet {
        column-gap: 40px;
      }
      @include mobile {
        grid-template-columns: 1fr;
      }
      .cert__item {
        position: relative;
        display: flex;
        align-items: center;
        &:before {
          content: '';
          width: 36px;
          flex: 0 0 36px;
          height: 36px;
          background: url('/wp-content/themes/blackriver/front/app/img/pdf.svg') no-repeat;
          background-size: contain;
          margin-right: 15px;
        }
        .item__link {
          color: var(--light-color);
        }
      }
    }
  }
  .review {
    margin-top: 80px;
    @include tablet {
      margin-top: 50px;
    }
    &__slider {
      margin-top: 37px;
      @include mobile {
        padding-left: 10px;
        padding-right: 10px;
      }
      .slider__item {
        display: flex;
        align-items: flex-start;
        .item {
          &__img {
            border-radius: 50%;
            object-fit: contain;
          }
          &__info {
            margin-top: 18px;
            margin-left: 10px;
          }
          &__name {

          }
          &__star {
            margin-top: 5px;
            width: 115px;
            height: 22px;
            background-image: url('/wp-content/themes/blackriver/front/app/img/star.svg');
            background-size: contain;
          }
          &__text {
            margin-top: 12px;
          }
          &__more {
            margin-top: 6px;
          }
        }
      }
      .slider__bullets {
        position: relative;
        margin-top: 50px;
        .swiper-pagination-bullet {
          background: transparent;
          opacity: 1;
          width: 22px;
          height: 22px;
          border: 3px solid transparent;
          background: #6B818A;
          box-shadow: inset 0px 0px 0px 22px var(--background-color);
          margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 8px);
          @include small-tablet {
            width: 18px;
            height: 18px;
          }
          &-active {
            background: linear-gradient(#B3BD15, #658F0F);
            //border-image: linear-gradient( #B3BD15, #658F0F);

          }
        }
      }
    }
  }

  .about {
    margin-top: 80px;
    @include small-tablet {
      margin-top: 50px;
    }
    &__title {
      @include mobile {
        text-align: center;
      }
    }
  }
  .contacts {
    margin-top: 52px;
    margin-bottom: 100px;
    @include small-tablet {
      margin-bottom: 40px;
    }
    &__title {
      margin: 0 0 32px 0;
      @include small-tablet {
        margin-bottom: 27px;
        text-align: center;
      }
    }
    &__address, &__mail, &__tel {
      margin-bottom: 17px;
      font-style: normal;
      display: flex;
      justify-content: flex-start;
      &:before {
        content: '';
        width: 18px;
        height: 18px;
        display: block;
        margin-right: 14px;
        @include tablet {
          width: 16px;
          height: 16px;
          background-size: contain;
        }
      }
    }
    &__mail:before {
      background-image: url("/wp-content/themes/blackriver/front/app/img/mail.svg");
      background-repeat: no-repeat;
      margin-top: 6px;
      justify-content: flex-start;
    }
    &__address:before {
      background-image: url("/wp-content/themes/blackriver/front/app/img/address.svg");
      background-repeat: no-repeat;
    }
    &__tel {
      text-decoration: none;
    }
    &__map {
      border-radius: 6px;
      border: 1px solid #374D57;
      overflow: hidden;
      height: 518px;
      @include small-tablet {
        height: 392px;
      }
    }
  }
  .services {
    margin-bottom: 80px;
    @include mobile {
      margin-top: 60px;
    }
    .title {
      @include mobile {
        text-align: center;
      }
    }
    &__title {
      @include mobile {
        text-align: center;
      }
    }
    &__cat {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 30px;
      margin-bottom: 80px;
      @include tablet {
        grid-template-columns: repeat(2, 1fr);
      }
      @include mobile {
        grid-template-columns: 1fr;
        justify-content: center;
      }
      &.yel {
        .cat__item {
          .item__btn {
            background-color: #FFD25E;
            color: #000;
          }
          .item__title {
            color: #000;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='264' height='68' viewBox='0 0 264 68' fill='none'%3E%3Cpath d='M257.982 0.127869H-67.6341C-71.7163 0.127869 -76.0825 3.43716 -77.3863 7.51939L-94.2547 60.336C-95.5584 64.4182 -93.3061 67.7275 -89.2238 67.7275H236.392C240.474 67.7275 244.841 64.4182 246.144 60.336L263.013 7.51939C264.317 3.43716 262.064 0.127869 257.982 0.127869Z' fill='%23FFD25E'/%3E%3C/svg%3E");
            &:before {
              background-color: #FFD25E;
            }
            &:after {
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='43' height='68' viewBox='0 0 43 68' fill='none'%3E%3Cpath d='M35.0335 0H29.4067C25.3168 0 20.9423 3.31556 19.6361 7.4055L2.73581 60.322C1.42958 64.412 3.68622 67.7275 7.77617 67.7275H13.4029C17.4928 67.7275 21.8673 64.412 23.1735 60.322L40.0738 7.4055C41.38 3.31556 39.1234 0 35.0335 0Z' fill='%23FFD25E'/%3E%3C/svg%3E");
            }
          }
        }
      }
      .cat__item {
        position: relative;
        display: block;
        overflow: hidden;
        img {
          border-radius: 8px;
          margin-top: 32px;
        }
        .item__price {
          display: block;
          margin: 14px 0 0 12px;
          font-size: 32px;
          font-style: normal;
          height: 35px;
          @include tablet {
            font-size: 16px;
          }
        }
        .item__btn {
          padding: 10px 20px;
          font-size: 24px;
          color: #fff;
          margin-left: 20px;
          margin-top: 10px;
          @include tablet {
            padding: 7px 29px 7px 19px;
            font-size: 14px;
            margin: 14px auto 0 auto;
            display: block;
          }
        }
        .item__title {
          position: absolute;
          transform: translate(20px, 48px);
          color: #fff;
          padding: 0 35px 0 0;
          height: 68px;
          display: block;
          width: fit-content;
          font-size: 24px;
          font-weight: 800;
          text-transform: uppercase;
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: right;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='296' height='68' viewBox='0 0 296 68' fill='none'%3E%3Cpath d='M289.982 0.127869H-35.6341C-39.7163 0.127869 -44.0825 3.43716 -45.3863 7.51939L-62.2547 60.336C-63.5584 64.4182 -61.3061 67.7275 -57.2238 67.7275H268.392C272.474 67.7275 276.841 64.4182 278.144 60.336L295.013 7.51939C296.317 3.43716 294.064 0.127869 289.982 0.127869Z' fill='%2369910F'/%3E%3C/svg%3E");
          @include tablet {
           height: 62px;
           font-size: 20px;
          }
          &:before {
            content: "";
            position: absolute;
            width: 100%;
            left: -50px;
            top: 0;
            background-color: var(--main-color);
            height: 68px;
            z-index: -1;
            @include tablet {
              height: 62px;
            }
          }
          &:after {
            content: "";
            position: absolute;
            width: 42px;
            height: 74px;
            right: -27px;
            top: 2px;
            z-index: -1;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='43' height='68' viewBox='0 0 43 68' fill='none'%3E%3Cpath d='M35.0335 0H29.4067C25.3168 0 20.9423 3.31556 19.6361 7.4055L2.73581 60.322C1.42958 64.412 3.68622 67.7275 7.77617 67.7275H13.4029C17.4928 67.7275 21.8673 64.412 23.1735 60.322L40.0738 7.4055C41.38 3.31556 39.1234 0 35.0335 0Z' fill='%2369910F'/%3E%3C/svg%3E");
            @include tablet {
              width: 42px;
              height: 62px;
              right: -32px;
              top: 0px;
            }
          }

        }
      }
    }
  }
}
.plus {
  @include mobile {
    padding: 40px 0;
  }
  &__title {
    @include mobile {
      text-align: center;
    }
  }

  &__wrapper {
    display: grid;
    grid-template-columns: repeat(3, 366px);
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 80px;
    @include tablet {
      grid-template-columns: repeat(2, 1fr);
      justify-content: center;
      gap: 40px;
      margin-top: 50px;
    }
    @include mobile {
      grid-template-columns: 100%;
      gap: 35px;
      margin-top: 35px;
    }
  }
  &__item {
    text-align: center;
    font-size: 18px;
    font-weight: 300;
    line-height: 120%;
    @include mobile {
      font-size: 14px;
    }
    .item {
      &__title {
        font-weight: 800;
        margin: 25px 0 0 0;
        height: 48px;
        @include mobile {
          height: inherit;
          margin: 10px 0 0 0;
        }
      }
      &__info {
        height: 92px;
        @include mobile {
          height: inherit;
        }
      }
    }
  }
}
.service {
  span[itemprop="itemListElement"] {
    @include mobile {
      display: none;
    }

  }
}
.wrapper-img {
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 27px;
  @include mobile {
    margin-top: 40px;
  }
  &.benz {
    background-image: url("/wp-content/themes/blackriver/front/app/img/wrap-benz.webp");
  }
  &.sand {
    background-image: url("/wp-content/themes/blackriver/front/app/img/wrap-sand.webp");
  }
  .delivery__train:before {
    top: -55px;
  }
  .delivery__auto:before {
    top: -66px;
  }
}
.types {
  &__btn {
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: fit-content;
  }
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }
  &__name {
    flex: 0 0 100%;
    order: -1;
    display: flex;
    gap: 27px;
    align-items: flex-start;
    @include mobile {
      overflow-x: scroll;
    }
    .name__item {
      border-radius: 4px;
      background: #3D515A;
      border: none;
      outline: none;
      padding: 10px 32px;
      cursor: pointer;
      @include mobile {
        padding: 5px 13px;
      }
      &.active {
        padding: 14px 43px;
        background-color: var(--main-color);
        transition: .3s;
        order: -1;
        @include mobile {
          padding: 8px 19px;
        }
      }
    }
  }
  &__tab {
    display: none;
    &.active {
      display: grid;
      flex: 0 0 100%;
      padding: 42px 0 133px;
      grid-template-columns: 45% 50%;
      gap: 40px;
      border-top: 2px solid var(--main-color);
      border-bottom: 2px solid var(--main-color);
      @include mobile {
        grid-template-columns: 1fr;
      }
    }
    .item__table {
      font-weight: 300;
      h3 {
        color: var(--main-color);
        font-size: 24px;
      }
      table {
        color: #fff;
        width: 100%;
          tr {
            padding: 16px 28px 13px 21px;
            display: flex;
            justify-content: space-between;
            &:nth-child(odd) {
              background-color: rgba(217, 217, 217, .1);
            }
          }
      }
    }
  }
}



.form {
  margin-top: 80px;
  .container {
    background: var(--background-block);
    padding-top: 50px;
    padding-bottom: 50px;
    @include small-tablet {
      padding-top: 23px;
      padding-bottom: 32px;
    }
    form {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      max-width: 959px;
      margin: 35px auto 0 auto;
      position: relative;
      @include small-tablet {
        padding: 0 41px;
      }
      @include mobile {
        padding: 0;
        margin-top: 10px;
      }
      label {
        flex: 0 0 425px;
        width: 425px;
        @include small-tablet {
          width: initial;
          flex: 0 0 45%;
        }
        @include mobile {
          flex: 0 0 100%;
          margin-top: 25px;
        }
        .form__name, .form__tel {
          margin-top: 8px;
          border-radius: 6px;
          border: 1px solid #374D57;
          background-color: var(--background-color);
          padding: 14px 8px;
          color: #6B818A;
          @include small-tablet {
            width: 100%;
          }
        }
        .wpcf7-not-valid-tip {
          font-size: 12px;
        }
      }
      .form__btn {
        margin: 35px auto 0 auto;
        padding: 10px 38px;
        font-size: 16px;
        @include small-tablet {
          margin-top: 28px;
        }
      }
      .wpcf7-spinner {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -40px;
        margin: 0 auto;
      }
      .wpcf7-response-output {
        flex: 0 0 100%;
        border: none;
        text-align: center;
        margin: 20px 0 25px;
        font-size: 14px;
        @include small-tablet {
          margin-bottom: 0;
        }
      }
    }
  }
  &__title {
    margin: 0;
    text-align: center;
  }
  &__text {
    text-align: center;
    font-size: 36px;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 120%;
    @include tablet {
      font-size: 26px;
      line-height: 32px;
    }
    @include mobile {
      font-size: 20px;
      line-height: 24px;
    }
  }
  &__message {
    margin-top: 35px;
    text-align: center;
    @include small-tablet {
      margin-top: 24px;
    }
  }
  &__social {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include small-tablet {
      margin-top: 16px;
    }
    .social__item {
      &:not(:last-child) {
        margin-right: 55px;
      }
    }
  }
}
.main.inner .news {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 60px 30px;
  margin-bottom: 75px;
}
@media (max-width: 1023px) {
  .main.inner .news {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    margin-bottom: 60px;
  }
}
@media (max-width: 767px) {
  .main.inner .news {
    -ms-grid-columns: 100%;
    grid-template-columns: 100%;
    gap: 30px 15px;
    margin-bottom: 50px;
  }
}
.main.inner .news__title {
  margin-bottom: 50px;
}
@media (max-width: 1023px) {
  .main.inner .news__title {
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .main.inner .news__title {
    margin-top: 80px;
    grid-area: auto;
  }
}
.main.inner .news .post-thumbnail .attachment-post-thumbnail {
  border-radius: 8px;
}
.main.inner .news__item .item__photo {
  border-radius: 8px;
}
.main.inner .news__item .item__date {
  font-size: 14px;
  margin-top: 8px;
}
@media (max-width: 1023px) {
  .main.inner .news__item .item__date {
    font-size: 12px;
  }
}
.main.inner .news__item .item__title {
  font-size: 24px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 120%;
  margin: 4px 0 0 0;
}
@media (max-width: 1280px) {
  .main.inner .news__item .item__title {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .main.inner .news__item .item__title {
    font-size: 16px;
  }
}
.main.inner .news__item .item__title .item__link {
  color: var(--light-color);
  text-decoration: none;
  width: 30px;
}
.main.inner .pagination .nav-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 47px;
}
@media (max-width: 1023px) {
  .main.inner .pagination .nav-links {
    margin-bottom: 40px;
  }
}
.main.inner .pagination .nav-links .page-numbers {
  border-radius: 3px;
  border: 1px solid var(--main-color);
  width: 30px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: var(--light-color);
  text-decoration: none;
}
.main.inner .pagination .nav-links .page-numbers:not(:last-child) {
  margin-right: 13px;
}
.main.inner .pagination .nav-links .page-numbers.current {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: var(--background-color);
  background-color: var(--main-color);
}
.main.inner .pagination .nav-links .page-numbers.prev, .main.inner .pagination .nav-links .page-numbers.next {
  border: none;
  color: var(--main-color);
  font-weight: 800;
}
@media (max-width: 767px) {
  .main.inner .article__title {
    margin-top: 80px;
  }
}
.main.inner .article__date {
  font-size: 14px;
  margin-top: 8px;
}
@media (max-width: 1023px) {
  .main.inner .article__date {
    font-size: 12px;
  }
}
.main.inner .article .info__photo {
  border-radius: 8px;
  float: left;
  margin-right: 38px;
  margin-bottom: 38px;
}
@media (max-width: 767px) {
  .main.inner .article .info__photo {
    margin-right: 0;
  }
}
.main.inner .article .nav-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 38px;
}
@media (max-width: 767px) {
  .main.inner .article .nav-links {
    margin-bottom: 28px;
  }
}
.main.inner .article .nav-links .nav-previous, .main.inner .article .nav-links .nav-next {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.main.inner .article .nav-links .nav-previous:before, .main.inner .article .nav-links .nav-next:before {
  content: "";
  width: 19px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 19px;
  flex: 0 0 19px;
  height: 17px;
  margin-right: 20px;
  margin-top: 5px;
  background-image: url("/wp-content/themes/blackriver/front/app/img/arrow.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
@media (max-width: 1023px) {
  .main.inner .article .nav-links .nav-previous:before, .main.inner .article .nav-links .nav-next:before {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 14px;
    flex: 0 0 14px;
    height: 11px;
    margin-right: 10px;
  }
}
.main.inner .article .nav-links .nav-next {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.main.inner .article .nav-links .nav-next:before {
  margin-left: 20px;
  margin-right: 0;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
@media (max-width: 1023px) {
  .main.inner .article .nav-links .nav-next:before {
    margin-right: 0;
    margin-left: 10px;
  }
}
