@charset "UTF-8";
:root {
  --font-family: "Roboto", sans-serif;
  --content-width: 1250px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --light-color: #fff;
  --background-color: #041720;
  --background-block: #05202D;
  --main-color: #69910F;
  --text-color: #041720;
  --link-color: #B0CB1F;
}

/* stylelint-disable */ /* stylelint-disable */ /* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */ /* stylelint-disable */ /* stylelint-disable value-keyword-case */
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  color: var(--light-color);
  min-width: 320px;
  min-height: 100%;
  font-weight: 300;
  font-family: var(--font-family, sans-serif);
  font-size: 20px;
  line-height: 23px;
  background-color: var(--background-color);
}
@media (max-width: 1280px) {
  .page__body {
    font-size: 16px;
    line-height: 19px;
  }
}
@media (max-width: 767px) {
  .page__body {
    font-weight: 300;
    font-size: 14px;
  }
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: hidden;
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.title {
  font-weight: 800;
  font-size: 48px;
  line-height: 56px;
  text-transform: uppercase;
}
@media (max-width: 1280px) {
  .title {
    font-size: 32px;
    line-height: 40px;
  }
}
@media (max-width: 767px) {
  .title {
    font-size: 26px;
    line-height: 30px;
  }
}

.subtitle {
  font-weight: 800;
  font-size: 36px;
  line-height: 42px;
  text-transform: uppercase;
}
@media (max-width: 1280px) {
  .subtitle {
    font-size: 26px;
    line-height: 32px;
  }
}
@media (max-width: 767px) {
  .subtitle {
    font-size: 20px;
    line-height: 24px;
  }
}

.list li {
  position: relative;
  display: flex;
}
.list li:before {
  content: "";
  width: 8px;
  flex: 0 0 8px;
  height: 11px;
  margin-right: 20px;
  margin-top: 5px;
  background-image: url("/wp-content/themes/blackriver/front/app/img/drop.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
.list li:not(:last-child) {
  margin-bottom: 31px;
}
@media (max-width: 1023px) {
  .list li:not(:last-child) {
    margin-bottom: 25px;
  }
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.btn {
  border-radius: 4px;
  background-color: var(--main-color);
  border: none;
  color: var(--text-color);
  text-transform: uppercase;
  padding: 5px 17px 5px 14px;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
}
.btn:hover {
  background-color: #D0A73E;
}
.btn:active {
  background-color: #E0BA5A;
}
@media (max-width: 1280px) {
  .btn {
    padding: 3px 10px;
    font-size: 12px;
  }
}

.tel {
  color: var(--light-color);
  position: relative;
  text-decoration: underline;
  text-decoration-style: dotted;
  text-underline-position: under;
  display: flex;
  align-items: center;
  margin-right: 35px;
}
@media (max-width: 1280px) {
  .tel {
    margin-bottom: 10px;
    margin-right: 0;
    justify-content: flex-end;
  }
}
.tel:before {
  content: "";
  background-image: url("/wp-content/themes/blackriver/front/app/img/tel.svg");
  background-repeat: no-repeat;
  width: 18px;
  height: 18px;
  display: block;
  margin-right: 7px;
}
@media (max-width: 1280px) {
  .tel:before {
    width: 16px;
    height: 16px;
    background-size: contain;
  }
}

a {
  color: var(--main-color);
  text-decoration: underline;
}

.more {
  position: relative;
  display: flex;
  color: var(--main-color);
  text-decoration: underline;
  border: none;
  outline: none;
  background: transparent;
}
.more:before {
  content: "";
  flex: 0 0 28px;
  width: 28px;
  height: 28px;
  margin-right: 9px;
  background-image: url("/wp-content/themes/blackriver/front/app/img/more.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
@media (max-width: 1023px) {
  .more:before {
    flex: 0 0 18px;
    height: 18px;
    margin-right: 3px;
  }
}

.flex {
  display: flex;
}

.flex-a-center {
  display: flex;
  align-items: center;
}

.flex-a-end {
  display: flex;
  align-items: flex-end;
}

.flex-j-center {
  display: flex;
  justify-content: center;
}

.flex-j-sb {
  display: flex;
  justify-content: space-between;
}

.flex-j-sa {
  display: flex;
  justify-content: space-around;
}

.grid {
  display: grid;
}

.main {
  margin-top: 24px;
}
@media (max-width: 767px) {
  .main {
    margin-top: -40px;
  }
}
.main .delivery .container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media (max-width: 1280px) {
  .main .delivery .container {
    justify-content: space-around;
  }
}
.main .delivery__wrapper {
  display: flex;
  justify-content: space-between;
  flex: 0 0 100%;
}
@media (max-width: 767px) {
  .main .delivery__wrapper {
    flex-direction: column-reverse;
  }
}
.main .delivery__btn {
  margin-top: 29px;
}
@media (max-width: 767px) {
  .main .delivery__btn {
    margin-top: 20px;
  }
}
@media (max-width: 1023px) {
  .main .delivery__text {
    flex: 0 0 50%;
  }
}
@media (max-width: 767px) {
  .main .delivery__text {
    flex: 0 0 auto;
  }
}
.main .delivery .btn {
  font-size: 24px;
  padding: 11px 20px 9px 20px;
}
@media (max-width: 1023px) {
  .main .delivery .btn {
    font-size: 14px;
    padding: 7px 10px 7px 10px;
  }
}
@media (max-width: 1023px) {
  .main .delivery__img {
    width: 422px;
    object-fit: contain;
  }
}
@media (max-width: 767px) {
  .main .delivery__img {
    width: 100%;
  }
}
.main .delivery__auto, .main .delivery__train {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 30px;
  flex: 0 0 580px;
  border-radius: 6px;
  border: 1px solid #374D57;
  padding: 37px 149px 29px 32px;
  position: relative;
}
@media (max-width: 1280px) {
  .main .delivery__auto, .main .delivery__train {
    flex: 0 0 45%;
  }
}
@media (max-width: 1023px) {
  .main .delivery__auto, .main .delivery__train {
    flex: 0 0 100%;
    display: block;
    padding: 19px 132px 19px 19px;
  }
}
@media (max-width: 767px) {
  .main .delivery__auto, .main .delivery__train {
    padding: 19px;
    margin-top: 40px;
  }
}
.main .delivery__auto:before, .main .delivery__train:before {
  content: "";
  width: 162px;
  height: 162px;
  position: absolute;
  right: -37px;
  top: -46px;
  background: url("/wp-content/themes/blackriver/front/app/img/hero-icon.svg");
  background-size: contain;
}
@media (max-width: 1023px) {
  .main .delivery__auto:before, .main .delivery__train:before {
    right: -19px;
    top: -32px;
    width: 150px;
    height: 150px;
  }
}
.main .delivery__auto .train__title, .main .delivery__auto .auto__title, .main .delivery__train .train__title, .main .delivery__train .auto__title {
  font-size: 20px;
  font-weight: 700;
  line-height: 110%;
  text-transform: uppercase;
  margin: 0 0 16px 0;
}
@media (max-width: 1280px) {
  .main .delivery__auto .train__title, .main .delivery__auto .auto__title, .main .delivery__train .train__title, .main .delivery__train .auto__title {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .main .delivery__auto .train__title, .main .delivery__auto .auto__title, .main .delivery__train .train__title, .main .delivery__train .auto__title {
    font-size: 14px;
    margin-bottom: 30px;
    max-width: 182px;
  }
}
.main .delivery__auto .train p, .main .delivery__auto .auto p, .main .delivery__train .train p, .main .delivery__train .auto p {
  font-size: 18px;
  line-height: 120%;
}
@media (max-width: 1280px) {
  .main .delivery__auto .train p, .main .delivery__auto .auto p, .main .delivery__train .train p, .main .delivery__train .auto p {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .main .delivery__auto .train p, .main .delivery__auto .auto p, .main .delivery__train .train p, .main .delivery__train .auto p {
    font-size: 14px;
  }
}
.main .delivery__auto .train__btn, .main .delivery__auto .auto__btn, .main .delivery__train .train__btn, .main .delivery__train .auto__btn {
  margin-top: 35px;
  width: fit-content;
}
@media (max-width: 1023px) {
  .main .delivery__auto .train__btn, .main .delivery__auto .auto__btn, .main .delivery__train .train__btn, .main .delivery__train .auto__btn {
    margin-top: 19px;
  }
}
@media (max-width: 767px) {
  .main .delivery__auto .train__btn, .main .delivery__auto .auto__btn, .main .delivery__train .train__btn, .main .delivery__train .auto__btn {
    margin: 0 auto;
    display: block;
  }
}
@media (max-width: 1023px) {
  .main .delivery__train {
    padding: 19px 19px 19px 149px;
  }
}
@media (max-width: 767px) {
  .main .delivery__train {
    padding: 19px;
  }
}
.main .delivery__train:before {
  background: url("/wp-content/themes/blackriver/front/app/img/hero-icon2.svg");
  background-size: contain;
}
@media (max-width: 1023px) {
  .main .delivery__train:before {
    right: inherit;
    left: -14px;
  }
}
@media (max-width: 767px) {
  .main .delivery__train:before {
    top: -20px;
  }
}
@media (max-width: 767px) {
  .main .delivery__train .train__title {
    margin-left: 132px;
  }
}
.main .advantages {
  margin-top: 56px;
  padding: 54px 0 73px;
  background: url("/wp-content/themes/blackriver/front/app/img/advantages-bg.webp") no-repeat;
  background-position: center;
  background-size: cover;
}
@media (max-width: 1023px) {
  .main .advantages {
    margin-top: 34px;
    padding: 39px 0 41px;
  }
}
.main .advantages__title {
  margin: 0 0 41px 0;
}
@media (max-width: 1023px) {
  .main .advantages__title {
    margin-bottom: 31px;
  }
}
.main .cert {
  margin-top: 75px;
}
@media (max-width: 1280px) {
  .main .cert {
    margin-top: 50px;
  }
}
@media (max-width: 1023px) {
  .main .cert {
    margin-top: 40px;
  }
}
@media (max-width: 767px) {
  .main .cert {
    margin-top: 25px;
  }
}
.main .cert__title {
  margin: 0 0 50px 0;
}
@media (max-width: 1023px) {
  .main .cert__title {
    margin-top: 35px;
  }
}
@media (max-width: 767px) {
  .main .cert__title {
    margin: 25px;
  }
}
.main .cert__list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px 100px;
}
@media (max-width: 1280px) {
  .main .cert__list {
    column-gap: 70px;
  }
}
@media (max-width: 1023px) {
  .main .cert__list {
    column-gap: 40px;
  }
}
@media (max-width: 767px) {
  .main .cert__list {
    grid-template-columns: 1fr;
  }
}
.main .cert__list .cert__item {
  position: relative;
  display: flex;
  align-items: center;
}
.main .cert__list .cert__item:before {
  content: "";
  width: 36px;
  flex: 0 0 36px;
  height: 36px;
  background: url("/wp-content/themes/blackriver/front/app/img/pdf.svg") no-repeat;
  background-size: contain;
  margin-right: 15px;
}
.main .cert__list .cert__item .item__link {
  color: var(--light-color);
}
.main .review {
  margin-top: 80px;
}
@media (max-width: 1280px) {
  .main .review {
    margin-top: 50px;
  }
}
.main .review__slider {
  margin-top: 37px;
}
@media (max-width: 767px) {
  .main .review__slider {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.main .review__slider .slider__item {
  display: flex;
  align-items: flex-start;
}
.main .review__slider .slider__item .item__img {
  border-radius: 50%;
  object-fit: contain;
}
.main .review__slider .slider__item .item__info {
  margin-top: 18px;
  margin-left: 10px;
}
.main .review__slider .slider__item .item__star {
  margin-top: 5px;
  width: 115px;
  height: 22px;
  background-image: url("/wp-content/themes/blackriver/front/app/img/star.svg");
  background-size: contain;
}
.main .review__slider .slider__item .item__text {
  margin-top: 12px;
}
.main .review__slider .slider__item .item__more {
  margin-top: 6px;
}
.main .review__slider .slider__bullets {
  position: relative;
  margin-top: 50px;
}
.main .review__slider .slider__bullets .swiper-pagination-bullet {
  background: transparent;
  opacity: 1;
  width: 22px;
  height: 22px;
  border: 3px solid transparent;
  background: #6B818A;
  box-shadow: inset 0px 0px 0px 22px var(--background-color);
  margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 8px);
}
@media (max-width: 1023px) {
  .main .review__slider .slider__bullets .swiper-pagination-bullet {
    width: 18px;
    height: 18px;
  }
}
.main .review__slider .slider__bullets .swiper-pagination-bullet-active {
  background: linear-gradient(#B3BD15, #658F0F);
}
.main .about {
  margin-top: 80px;
}
@media (max-width: 1023px) {
  .main .about {
    margin-top: 50px;
  }
}
@media (max-width: 767px) {
  .main .about__title {
    text-align: center;
  }
}
.main .contacts {
  margin-top: 52px;
  margin-bottom: 100px;
}
@media (max-width: 1023px) {
  .main .contacts {
    margin-bottom: 40px;
  }
}
.main .contacts__title {
  margin: 0 0 32px 0;
}
@media (max-width: 1023px) {
  .main .contacts__title {
    margin-bottom: 27px;
    text-align: center;
  }
}
.main .contacts__address, .main .contacts__mail, .main .contacts__tel {
  margin-bottom: 17px;
  font-style: normal;
  display: flex;
  justify-content: flex-start;
}
.main .contacts__address:before, .main .contacts__mail:before, .main .contacts__tel:before {
  content: "";
  width: 18px;
  height: 18px;
  display: block;
  margin-right: 14px;
}
@media (max-width: 1280px) {
  .main .contacts__address:before, .main .contacts__mail:before, .main .contacts__tel:before {
    width: 16px;
    height: 16px;
    background-size: contain;
  }
}
.main .contacts__mail:before {
  background-image: url("/wp-content/themes/blackriver/front/app/img/mail.svg");
  background-repeat: no-repeat;
  margin-top: 6px;
  justify-content: flex-start;
}
.main .contacts__address:before {
  background-image: url("/wp-content/themes/blackriver/front/app/img/address.svg");
  background-repeat: no-repeat;
}
.main .contacts__tel {
  text-decoration: none;
}
.main .contacts__map {
  border-radius: 6px;
  border: 1px solid #374D57;
  overflow: hidden;
  height: 518px;
}
@media (max-width: 1023px) {
  .main .contacts__map {
    height: 392px;
  }
}
.main .services {
  margin-bottom: 80px;
}
@media (max-width: 767px) {
  .main .services {
    margin-top: 60px;
  }
}
@media (max-width: 767px) {
  .main .services .title {
    text-align: center;
  }
}
@media (max-width: 767px) {
  .main .services__title {
    text-align: center;
  }
}
.main .services__cat {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-bottom: 80px;
}
@media (max-width: 1280px) {
  .main .services__cat {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 767px) {
  .main .services__cat {
    grid-template-columns: 1fr;
    justify-content: center;
  }
}
.main .services__cat.yel .cat__item .item__btn {
  background-color: #FFD25E;
  color: #000;
}
.main .services__cat.yel .cat__item .item__title {
  color: #000;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='264' height='68' viewBox='0 0 264 68' fill='none'%3E%3Cpath d='M257.982 0.127869H-67.6341C-71.7163 0.127869 -76.0825 3.43716 -77.3863 7.51939L-94.2547 60.336C-95.5584 64.4182 -93.3061 67.7275 -89.2238 67.7275H236.392C240.474 67.7275 244.841 64.4182 246.144 60.336L263.013 7.51939C264.317 3.43716 262.064 0.127869 257.982 0.127869Z' fill='%23FFD25E'/%3E%3C/svg%3E");
}
.main .services__cat.yel .cat__item .item__title:before {
  background-color: #FFD25E;
}
.main .services__cat.yel .cat__item .item__title:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='43' height='68' viewBox='0 0 43 68' fill='none'%3E%3Cpath d='M35.0335 0H29.4067C25.3168 0 20.9423 3.31556 19.6361 7.4055L2.73581 60.322C1.42958 64.412 3.68622 67.7275 7.77617 67.7275H13.4029C17.4928 67.7275 21.8673 64.412 23.1735 60.322L40.0738 7.4055C41.38 3.31556 39.1234 0 35.0335 0Z' fill='%23FFD25E'/%3E%3C/svg%3E");
}
.main .services__cat .cat__item {
  position: relative;
  display: block;
  overflow: hidden;
}
.main .services__cat .cat__item img {
  border-radius: 8px;
  margin-top: 32px;
}
.main .services__cat .cat__item .item__price {
  display: block;
  margin: 14px 0 0 12px;
  font-size: 32px;
  font-style: normal;
  height: 35px;
}
@media (max-width: 1280px) {
  .main .services__cat .cat__item .item__price {
    font-size: 16px;
  }
}
.main .services__cat .cat__item .item__btn {
  padding: 10px 20px;
  font-size: 24px;
  color: #fff;
  margin-left: 20px;
  margin-top: 10px;
}
@media (max-width: 1280px) {
  .main .services__cat .cat__item .item__btn {
    padding: 7px 29px 7px 19px;
    font-size: 14px;
    margin: 14px auto 0 auto;
    display: block;
  }
}
.main .services__cat .cat__item .item__title {
  position: absolute;
  transform: translate(20px, 48px);
  color: #fff;
  padding: 0 35px 0 0;
  height: 68px;
  display: block;
  width: fit-content;
  font-size: 24px;
  font-weight: 800;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='296' height='68' viewBox='0 0 296 68' fill='none'%3E%3Cpath d='M289.982 0.127869H-35.6341C-39.7163 0.127869 -44.0825 3.43716 -45.3863 7.51939L-62.2547 60.336C-63.5584 64.4182 -61.3061 67.7275 -57.2238 67.7275H268.392C272.474 67.7275 276.841 64.4182 278.144 60.336L295.013 7.51939C296.317 3.43716 294.064 0.127869 289.982 0.127869Z' fill='%2369910F'/%3E%3C/svg%3E");
}
@media (max-width: 1280px) {
  .main .services__cat .cat__item .item__title {
    height: 62px;
    font-size: 20px;
  }
}
.main .services__cat .cat__item .item__title:before {
  content: "";
  position: absolute;
  width: 100%;
  left: -50px;
  top: 0;
  background-color: var(--main-color);
  height: 68px;
  z-index: -1;
}
@media (max-width: 1280px) {
  .main .services__cat .cat__item .item__title:before {
    height: 62px;
  }
}
.main .services__cat .cat__item .item__title:after {
  content: "";
  position: absolute;
  width: 42px;
  height: 74px;
  right: -27px;
  top: 2px;
  z-index: -1;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='43' height='68' viewBox='0 0 43 68' fill='none'%3E%3Cpath d='M35.0335 0H29.4067C25.3168 0 20.9423 3.31556 19.6361 7.4055L2.73581 60.322C1.42958 64.412 3.68622 67.7275 7.77617 67.7275H13.4029C17.4928 67.7275 21.8673 64.412 23.1735 60.322L40.0738 7.4055C41.38 3.31556 39.1234 0 35.0335 0Z' fill='%2369910F'/%3E%3C/svg%3E");
}
@media (max-width: 1280px) {
  .main .services__cat .cat__item .item__title:after {
    width: 42px;
    height: 62px;
    right: -32px;
    top: 0px;
  }
}

@media (max-width: 767px) {
  .plus {
    padding: 40px 0;
  }
}
@media (max-width: 767px) {
  .plus__title {
    text-align: center;
  }
}
.plus__wrapper {
  display: grid;
  grid-template-columns: repeat(3, 366px);
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 80px;
}
@media (max-width: 1280px) {
  .plus__wrapper {
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    gap: 40px;
    margin-top: 50px;
  }
}
@media (max-width: 767px) {
  .plus__wrapper {
    grid-template-columns: 100%;
    gap: 35px;
    margin-top: 35px;
  }
}
.plus__item {
  text-align: center;
  font-size: 18px;
  font-weight: 300;
  line-height: 120%;
}
@media (max-width: 767px) {
  .plus__item {
    font-size: 14px;
  }
}
.plus__item .item__title {
  font-weight: 800;
  margin: 25px 0 0 0;
  height: 48px;
}
@media (max-width: 767px) {
  .plus__item .item__title {
    height: inherit;
    margin: 10px 0 0 0;
  }
}
.plus__item .item__info {
  height: 92px;
}
@media (max-width: 767px) {
  .plus__item .item__info {
    height: inherit;
  }
}

@media (max-width: 767px) {
  .service span[itemprop=itemListElement] {
    display: none;
  }
}

.wrapper-img {
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 27px;
}
@media (max-width: 767px) {
  .wrapper-img {
    margin-top: 40px;
  }
}
.wrapper-img.benz {
  background-image: url("/wp-content/themes/blackriver/front/app/img/wrap-benz.webp");
}
.wrapper-img.sand {
  background-image: url("/wp-content/themes/blackriver/front/app/img/wrap-sand.webp");
}
.wrapper-img .delivery__train:before {
  top: -55px;
}
.wrapper-img .delivery__auto:before {
  top: -66px;
}

.types__btn {
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: fit-content;
}
.types__wrapper {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.types__name {
  flex: 0 0 100%;
  order: -1;
  display: flex;
  gap: 27px;
  align-items: flex-start;
}
@media (max-width: 767px) {
  .types__name {
    overflow-x: scroll;
  }
}
.types__name .name__item {
  border-radius: 4px;
  background: #3D515A;
  border: none;
  outline: none;
  padding: 10px 32px;
  cursor: pointer;
}
@media (max-width: 767px) {
  .types__name .name__item {
    padding: 5px 13px;
  }
}
.types__name .name__item.active {
  padding: 14px 43px;
  background-color: var(--main-color);
  transition: 0.3s;
  order: -1;
}
@media (max-width: 767px) {
  .types__name .name__item.active {
    padding: 8px 19px;
  }
}
.types__tab {
  display: none;
}
.types__tab.active {
  display: grid;
  flex: 0 0 100%;
  padding: 42px 0 133px;
  grid-template-columns: 45% 50%;
  gap: 40px;
  border-top: 2px solid var(--main-color);
  border-bottom: 2px solid var(--main-color);
}
@media (max-width: 767px) {
  .types__tab.active {
    grid-template-columns: 1fr;
  }
}
.types__tab .item__table {
  font-weight: 300;
}
.types__tab .item__table h3 {
  color: var(--main-color);
  font-size: 24px;
}
.types__tab .item__table table {
  color: #fff;
  width: 100%;
}
.types__tab .item__table table tr {
  padding: 16px 28px 13px 21px;
  display: flex;
  justify-content: space-between;
}
.types__tab .item__table table tr:nth-child(odd) {
  background-color: rgba(217, 217, 217, 0.1);
}

.form {
  margin-top: 80px;
}
.form .container {
  background: var(--background-block);
  padding-top: 50px;
  padding-bottom: 50px;
}
@media (max-width: 1023px) {
  .form .container {
    padding-top: 23px;
    padding-bottom: 32px;
  }
}
.form .container form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 959px;
  margin: 35px auto 0 auto;
  position: relative;
}
@media (max-width: 1023px) {
  .form .container form {
    padding: 0 41px;
  }
}
@media (max-width: 767px) {
  .form .container form {
    padding: 0;
    margin-top: 10px;
  }
}
.form .container form label {
  flex: 0 0 425px;
  width: 425px;
}
@media (max-width: 1023px) {
  .form .container form label {
    width: initial;
    flex: 0 0 45%;
  }
}
@media (max-width: 767px) {
  .form .container form label {
    flex: 0 0 100%;
    margin-top: 25px;
  }
}
.form .container form label .form__name, .form .container form label .form__tel {
  margin-top: 8px;
  border-radius: 6px;
  border: 1px solid #374D57;
  background-color: var(--background-color);
  padding: 14px 8px;
  color: #6B818A;
}
@media (max-width: 1023px) {
  .form .container form label .form__name, .form .container form label .form__tel {
    width: 100%;
  }
}
.form .container form label .wpcf7-not-valid-tip {
  font-size: 12px;
}
.form .container form .form__btn {
  margin: 35px auto 0 auto;
  padding: 10px 38px;
  font-size: 16px;
}
@media (max-width: 1023px) {
  .form .container form .form__btn {
    margin-top: 28px;
  }
}
.form .container form .wpcf7-spinner {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -40px;
  margin: 0 auto;
}
.form .container form .wpcf7-response-output {
  flex: 0 0 100%;
  border: none;
  text-align: center;
  margin: 20px 0 25px;
  font-size: 14px;
}
@media (max-width: 1023px) {
  .form .container form .wpcf7-response-output {
    margin-bottom: 0;
  }
}
.form__title {
  margin: 0;
  text-align: center;
}
.form__text {
  text-align: center;
  font-size: 36px;
  font-weight: 300;
  text-transform: uppercase;
  line-height: 120%;
}
@media (max-width: 1280px) {
  .form__text {
    font-size: 26px;
    line-height: 32px;
  }
}
@media (max-width: 767px) {
  .form__text {
    font-size: 20px;
    line-height: 24px;
  }
}
.form__message {
  margin-top: 35px;
  text-align: center;
}
@media (max-width: 1023px) {
  .form__message {
    margin-top: 24px;
  }
}
.form__social {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1023px) {
  .form__social {
    margin-top: 16px;
  }
}
.form__social .social__item:not(:last-child) {
  margin-right: 55px;
}

.main.inner .news {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 60px 30px;
  margin-bottom: 75px;
}

@media (max-width: 1023px) {
  .main.inner .news {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    margin-bottom: 60px;
  }
}
@media (max-width: 767px) {
  .main.inner .news {
    -ms-grid-columns: 100%;
    grid-template-columns: 100%;
    gap: 30px 15px;
    margin-bottom: 50px;
  }
}
.main.inner .news__title {
  margin-bottom: 50px;
}

@media (max-width: 1023px) {
  .main.inner .news__title {
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .main.inner .news__title {
    margin-top: 80px;
    grid-area: auto;
  }
}
.main.inner .news .post-thumbnail .attachment-post-thumbnail {
  border-radius: 8px;
}

.main.inner .news__item .item__photo {
  border-radius: 8px;
}

.main.inner .news__item .item__date {
  font-size: 14px;
  margin-top: 8px;
}

@media (max-width: 1023px) {
  .main.inner .news__item .item__date {
    font-size: 12px;
  }
}
.main.inner .news__item .item__title {
  font-size: 24px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 120%;
  margin: 4px 0 0 0;
}

@media (max-width: 1280px) {
  .main.inner .news__item .item__title {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .main.inner .news__item .item__title {
    font-size: 16px;
  }
}
.main.inner .news__item .item__title .item__link {
  color: var(--light-color);
  text-decoration: none;
  width: 30px;
}

.main.inner .pagination .nav-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 47px;
}

@media (max-width: 1023px) {
  .main.inner .pagination .nav-links {
    margin-bottom: 40px;
  }
}
.main.inner .pagination .nav-links .page-numbers {
  border-radius: 3px;
  border: 1px solid var(--main-color);
  width: 30px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: var(--light-color);
  text-decoration: none;
}

.main.inner .pagination .nav-links .page-numbers:not(:last-child) {
  margin-right: 13px;
}

.main.inner .pagination .nav-links .page-numbers.current {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: var(--background-color);
  background-color: var(--main-color);
}

.main.inner .pagination .nav-links .page-numbers.prev, .main.inner .pagination .nav-links .page-numbers.next {
  border: none;
  color: var(--main-color);
  font-weight: 800;
}

@media (max-width: 767px) {
  .main.inner .article__title {
    margin-top: 80px;
  }
}
.main.inner .article__date {
  font-size: 14px;
  margin-top: 8px;
}

@media (max-width: 1023px) {
  .main.inner .article__date {
    font-size: 12px;
  }
}
.main.inner .article .info__photo {
  border-radius: 8px;
  float: left;
  margin-right: 38px;
  margin-bottom: 38px;
}

@media (max-width: 767px) {
  .main.inner .article .info__photo {
    margin-right: 0;
  }
}
.main.inner .article .nav-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 38px;
}

@media (max-width: 767px) {
  .main.inner .article .nav-links {
    margin-bottom: 28px;
  }
}
.main.inner .article .nav-links .nav-previous, .main.inner .article .nav-links .nav-next {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.main.inner .article .nav-links .nav-previous:before, .main.inner .article .nav-links .nav-next:before {
  content: "";
  width: 19px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 19px;
  flex: 0 0 19px;
  height: 17px;
  margin-right: 20px;
  margin-top: 5px;
  background-image: url("/wp-content/themes/blackriver/front/app/img/arrow.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

@media (max-width: 1023px) {
  .main.inner .article .nav-links .nav-previous:before, .main.inner .article .nav-links .nav-next:before {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 14px;
    flex: 0 0 14px;
    height: 11px;
    margin-right: 10px;
  }
}
.main.inner .article .nav-links .nav-next {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.main.inner .article .nav-links .nav-next:before {
  margin-left: 20px;
  margin-right: 0;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

@media (max-width: 1023px) {
  .main.inner .article .nav-links .nav-next:before {
    margin-right: 0;
    margin-left: 10px;
  }
}
.header {
  padding-top: 30px;
  background-color: var(--background-color);
  z-index: 2;
}
@media (max-width: 1280px) {
  .header {
    position: relative;
    padding-top: 7px;
  }
}
.header .container {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1280px) {
  .header .container {
    align-items: center;
  }
}
.header .container.header__container {
  align-items: center;
  justify-content: center;
  background: var(--background-block);
  height: 40px;
  display: none;
}
@media (max-width: 1280px) {
  .header .container.header__container {
    display: flex;
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  .header .container.header__container {
    height: 30px;
  }
}
.header .container.header__container .burger {
  display: none;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: var(--light-color);
  border: none;
  background: transparent;
  position: relative;
  align-items: center;
  display: flex;
  cursor: pointer;
}
.header .container.header__container .burger:focus {
  outline: none;
}
.header .container.header__container .burger__icon {
  width: 17px;
  height: 17px;
  margin-right: 10px;
}
.header .container.header__container .burger__icon:before, .header .container.header__container .burger__icon:after, .header .container.header__container .burger__icon div {
  background: var(--main-color);
  content: "";
  display: block;
  height: 1px;
  border-radius: 3px;
  margin: 4px 0;
  transition: 0.5s;
}
.header .container.header__container .burger.active .burger__icon:before {
  transform: translateY(4px) rotate(135deg);
  background: #CDA130;
}
.header .container.header__container .burger.active .burger__icon:after {
  transform: translateY(-6px) rotate(-135deg);
  background: #CDA130;
}
.header .container.header__container .burger.active .burger__icon div {
  transform: scale(0);
}
@media (max-width: 1280px) {
  .header__logo {
    flex: 0 0 120px;
    width: 120px;
    height: 62px;
  }
}
.header__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 35px;
}
@media (max-width: 1280px) {
  .header__wrapper {
    flex-direction: column;
    justify-content: center;
    margin-top: 0;
  }
}
.header__menu {
  padding: 0;
  margin: 47px 0 0 0;
  flex: 0 0 100%;
}
@media (max-width: 1280px) {
  .header__menu {
    position: absolute;
    transform: translateX(-200vw);
    width: 100%;
    height: 470px;
    background-color: var(--background-block);
    left: 0;
    top: 72px;
    transition: 0.6s;
  }
}
@media (max-width: 767px) {
  .header__menu {
    top: 61px;
  }
}
.header__menu.active {
  transform: translateX(0);
}
.header__menu .menu__list {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
  width: 100%;
  margin: 0;
}
@media (max-width: 1280px) {
  .header__menu .menu__list {
    margin-top: 68px;
    flex-direction: column;
    justify-content: flex-start;
  }
}
.header__menu .menu__list .list__item {
  font-weight: 400;
  text-transform: uppercase;
}
.header__menu .menu__list .list__item:not(:last-child) {
  margin-right: 32px;
}
@media (max-width: 1280px) {
  .header__menu .menu__list .list__item:not(:last-child) {
    margin-right: 0;
    margin-bottom: 36px;
  }
}
.header__menu .menu__list .list__item .item__link {
  color: var(--light-color);
  text-decoration: none;
}
.header__menu .menu__list .list__item .item__link:hover {
  color: var(--main-color);
}
.header__tel {
  margin-right: 35px;
}
@media (max-width: 1280px) {
  .header__tel {
    margin-bottom: 10px;
    margin-right: 0;
    justify-content: flex-end;
  }
}

.footer {
  background-color: var(--background-block);
  padding: 10px 0 41px 0;
}
@media (max-width: 1023px) {
  .footer {
    padding-top: 20px;
  }
}
.footer .container {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1023px) {
  .footer .container {
    flex-direction: column;
    align-items: center;
  }
}
.footer__wrapper.bottom {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
@media (max-width: 1023px) {
  .footer__wrapper.bottom {
    align-items: center;
  }
}
.footer__menu {
  margin-top: 40px;
  padding: 0;
}
.footer__menu .menu__list {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
  width: 100%;
  margin: 0;
}
@media (max-width: 1023px) {
  .footer__menu .menu__list {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
  }
}
.footer__menu .menu__list .list__item {
  font-weight: 400;
  font-size: 16px;
  text-transform: uppercase;
}
.footer__menu .menu__list .list__item:not(:last-child) {
  margin-right: 32px;
}
@media (max-width: 1023px) {
  .footer__menu .menu__list .list__item:not(:last-child) {
    margin-right: 0;
    margin-bottom: 19px;
  }
}
.footer__menu .menu__list .list__item .item__link {
  color: var(--light-color);
  text-decoration: none;
}
.footer__menu .menu__list .list__item .item__link:hover {
  color: var(--main-color);
}
.footer__tel {
  margin-top: 27px;
}
@media (max-width: 1280px) {
  .footer__tel {
    justify-content: flex-start;
  }
}
@media (max-width: 1023px) {
  .footer__tel {
    justify-content: center;
    margin-top: 15px;
  }
}
.footer__social {
  margin: 42px 0 0 0;
  padding: 0;
  list-style: none;
  display: flex;
}
.footer__social .social__item:not(:last-child) {
  margin-right: 32px;
}

.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s all;
  z-index: 20;
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 0;
}
@media (max-width: 1920px) {
  .popup {
    width: 570px;
  }
}
@media (max-width: 767px) {
  .popup {
    padding-left: 0;
    padding-right: 0;
    width: 90%;
  }
}
.popup.active {
  transform: translate(-50%, -50%);
  transition: 0.5s all;
}
.popup .container {
  border-radius: 6px;
  position: relative;
}
@media (max-width: 1920px) {
  .popup .container .form__title {
    font-size: 27px;
  }
}
@media (max-width: 1920px) {
  .popup .container form label {
    margin-bottom: 13px;
  }
}
.popup .container form label .form__name, .popup .container form label .form__tel {
  width: 425px;
}
@media (max-width: 767px) {
  .popup .container form label .form__name, .popup .container form label .form__tel {
    width: 100%;
  }
}
.popup__bg {
  z-index: 20;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  pointer-events: none;
  transition: 0.5s all;
}
.popup__bg.active {
  opacity: 1;
  pointer-events: all;
  transition: 0.5s all;
}
.popup .close-popup {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.popup .close-popup:after {
  display: inline-block;
  content: "×";
  font-size: 40px;
  color: #CDA130;
}
.popup .close-popup:hover:before, .popup .close-popup:hover:after {
  background-color: var(--main-color);
}