.header {
  padding-top: 30px;
  background-color: var(--background-color);
  z-index: 2;
  @include tablet {
    position: relative;
    padding-top: 7px;
  }
  .container {
    display: flex;
    justify-content: space-between;
    @include tablet {
      align-items: center;
    }
    &.header__container {
      align-items: center;
      justify-content: center;
      background: var(--background-block);
      height: 40px;
      display: none;

      @include tablet {
        display: flex;
        margin-top: 10px;
      }
      @include mobile {
        height: 30px;
      }
      .burger {
        display: none;
        line-height: 16px;
        text-align: center;
        text-transform: uppercase;
        color: var(--light-color);
        border: none;
        background: transparent;
        position: relative;
        align-items: center;
        display: flex;
        cursor: pointer;
        &:focus {
          outline:  none;
        }
        &__icon {
          width: 17px;
          height: 17px;
          margin-right: 10px;
          &:before,
          &:after,
          & div {
            background: var(--main-color);
            content: "";
            display: block;
            height: 1px;
            border-radius: 3px;
            margin: 4px 0;
            transition: 0.5s;
          }
        }
        &.active .burger__icon:before {
          transform: translateY(4px) rotate(135deg);
          background: #CDA130;
        }
        &.active .burger__icon:after {
          transform: translateY(-6px) rotate(-135deg);
          background: #CDA130;
        }
        &.active .burger__icon div {
          transform: scale(0);
        }

      }
    }

  }
  &__logo {
    @include tablet {
      flex: 0 0 120px;
      width: 120px;
      height: 62px;
    }
  }
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: 35px;
    @include tablet {
      flex-direction: column;
      justify-content: center;
      margin-top: 0;
    }
  }
  &__menu {
    padding: 0;
    margin: 47px 0 0 0;
    flex: 0 0 100%;
    @include tablet {
      position: absolute;
      transform: translateX(-200vw);
      width: 100%;
      height: 470px;
      background-color: var(--background-block);
      left: 0;
      top: 72px;
      transition: .6s;
    }
    @include mobile {
      top: 61px;
    }
    &.active {
      transform: translateX(0);
    }
    .menu__list {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0;
      width: 100%;
      margin: 0;
      @include tablet {
        margin-top: 68px;
        flex-direction: column;
        justify-content: flex-start;
      }
      .list__item {
        font-weight: 400;
        text-transform: uppercase;
        &:not(:last-child) {
          margin-right: 32px;
          @include tablet {
            margin-right: 0;
            margin-bottom: 36px;
          }
        }
        .item__link {
          color: var(--light-color);
          text-decoration: none;
          &:hover {
            color: var(--main-color);
          }
        }
      }
    }
  }
  &__tel {
    margin-right: 35px;
    @include tablet {
      margin-bottom: 10px;
      margin-right: 0;
      justify-content: flex-end;
    }
  }
}

